var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section-banner',{attrs:{"model":_vm.section}}),_c('v-container',{staticClass:"mt-12 mb-12"},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"80%"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0 mt-3",attrs:{"headers":_vm.headers,"items":_vm.cartRows,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(item.price,"R", 2, { thousandsSeparator: ",", decimalSeparator: ".", }))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,"R", 2, { thousandsSeparator: ",", decimalSeparator: ".", }))+" ")])]}},{key:"item.bin",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.removeProductFromCart(item)}}},[_vm._v("mdi-delete")])]}}])}),_c('hr'),_c('v-row',{staticClass:"mt-2 mr-0"},[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{staticStyle:{"background-color":"#e6e6e6"},attrs:{"cols":"4"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.summary,"items":_vm.summaryRows,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,"R", 2, { thousandsSeparator: ",", decimalSeparator: ".", }))+" ")])]}}])})],1)],1)],1),_c('v-card-actions',{staticClass:"cart__buttons"},[(_vm.cartRows.length > 0)?_c('v-btn',{staticClass:"btn__secondary-colour",attrs:{"outlined":"","large":""},on:{"click":_vm.abandonCart}},[_vm._v("Abandon Cart")]):_vm._e(),(_vm.cartRows.length <= 0)?_c('v-btn',{staticClass:"btn__primary-colour",attrs:{"large":"","href":"/products"}},[_vm._v("View DIY Documents")]):_vm._e(),(_vm.cartRows.length > 0)?_c('v-btn',{staticClass:"btn__primary-colour",attrs:{"href":"/commerce/checkout","large":""}},[_vm._v("Checkout")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }