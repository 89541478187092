<template>
  <div>
    <section-banner :model="section"></section-banner>
    <v-container class="mt-12 mb-12">
      <v-row>
        <v-col>
          <v-card width="80%" class="mx-auto">
            <v-card-text>
              <v-data-table :headers="headers" :items="cartRows" :items-per-page="10" hide-default-footer class="elevation-0 mt-3">
                <template v-slot:item.price="{ item }">
                  <h5>
                    {{
                      item.price
                        | currency("R", 2, {
                          thousandsSeparator: ",",
                          decimalSeparator: ".",
                        })
                    }}
                  </h5>
                </template>
                <template v-slot:item.amount="{ item }">
                  <h5>
                    {{
                      item.amount
                        | currency("R", 2, {
                          thousandsSeparator: ",",
                          decimalSeparator: ".",
                        })
                    }}
                  </h5>
                </template>
                <template v-slot:item.bin="{ item }">
                  <v-icon @click="removeProductFromCart(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
              <hr />
              <v-row class="mt-2 mr-0">
                <v-col cols="8"></v-col>
                <v-col cols="4" style="background-color:#e6e6e6;">
                  <v-data-table :headers="summary" :items="summaryRows" hide-default-header hide-default-footer class="elevation-1">
                    <template v-slot:item.amount="{ item }">
                      <h5>
                        {{
                          item.amount
                            | currency("R", 2, {
                              thousandsSeparator: ",",
                              decimalSeparator: ".",
                            })
                        }}
                      </h5>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="cart__buttons">
              <v-btn v-if="cartRows.length > 0" class="btn__secondary-colour" outlined large @click="abandonCart">Abandon Cart</v-btn>
              <v-btn v-if="cartRows.length <= 0" large class="btn__primary-colour" href="/products">View DIY Documents</v-btn>
              <v-btn v-if="cartRows.length > 0" href="/commerce/checkout" large class="btn__primary-colour">Checkout</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import SectionBanner from "@/components/Frontend/SectionBanner";
export default {
  components: {
    SectionBanner,
  },
  data() {
    return {
      skipQuery: true,

      totalItems: 0,

      cart: {},

      products: [],
      cartRows: [],
      summaryRows: [],

      section: {
        BannerSmall: true,
        Title: "Cart",
        Subtitle: "Your Selected DIY Documents",
        Description: "Review your products below and continue to the checkout.",
        img: require("@/assets/img/background.jpg"),
      },

      headers: [
        { text: "Item", align: "start", sortable: false, value: "item" },
        { text: "Qty", value: "qty", align: "start" },
        { text: "Item Price", value: "price", align: "start" },
        { text: "Total", value: "amount", align: "start" },
        { text: "Delete", value: "bin", sortable: false, align: "start" },
      ],

      summary: [
        { text: "summary", align: "start", sortable: false, value: "summary" },
        { text: "amount", align: "right", sortable: false, value: "amount" },
      ],
    };
  },
  apollo: {
    orders: {
      query: gql`
        query Orders($limit: Int!, $skip: Int!, $query: JSON!) {
          orders(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              totalAmount
              products
              createdAt
            }
          }
        }
      `,
      skip() {
        return this.skipQuery;
      },
      variables() {
        const self = this;
        return {
          limit: 1,
          skip: 0,
          query: {
            createdBy: self.me.id,
            payment: "pending",
          },
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        if (!response?.data?.orders?.records?.length) {
          this.initState();
          return;
        }
        this.cart = this.orders.records[0];
        this.products = this.cart.products;
        this.totalItems = this.products.length;

        const grouped = _.groupBy(this.products, "id");
        const entries = Object.entries(grouped);

        this.cartRows = [];
        for (const [product_id, value] of entries) {
          this.cartRows.push({
            id: value[0].id,
            item: value[0].title,
            qty: value.length,
            price: value[0].price,
            amount: value.length * value[0].price,
          });
        }

        this.summaryRows = [];
        const total = _.map(this.cartRows, (item) => {
          return item.qty * item.price;
        }).reduce((a, b) => a + b);
        this.summaryRows.push({
          summary: "Total Excl Vat",
          amount: total / 1.15,
        });
        this.summaryRows.push({
          summary: "Vat",
          amount: (total / 1.15) * 0.15,
        });
        this.summaryRows.push({
          summary: "Total Incl Vat",
          amount: total,
        });
      },
    },
    me: {
      query: gql`
        query {
          me {
            id
          }
        }
      `,
      fetchPolicy: "network-only",
      result() {
        this.$apollo.queries.orders.skip = false;
        this.$apollo.queries.orders.refetch();
      },
    },
  },
  methods: {
    initState() {
      this.totalItems = 0;
      this.cart = {};
      this.products = [];
      this.cartRows = [];
      this.summaryRows = [];
    },
    async removeProductFromCart(product) {
      console.log(product);
      const self = this;
      const response = await this.$apollo.mutate({
        mutation: gql`
          mutation($id: ID!, $product: ID!) {
            removeProductFromOrder(id: $id, product: $product) {
              id
              updated
            }
          }
        `,
        variables: {
          id: self.cart.id,
          product: product.id,
        },
      });
      console.log(response);
      this.$apollo.queries.orders.skip = false;
      this.$apollo.queries.orders.refetch();

      this.$eventHub.$emit("updateCartMenu");
    },
    async abandonCart() {
      const self = this;
      const response = await this.$apollo.mutate({
        mutation: gql`
          mutation($id: ID!) {
            deleteOrder(id: $id) {
              id
              deleted
            }
          }
        `,
        variables: {
          id: self.cart.id,
        },
      });

      this.$apollo.queries.orders.skip = false;
      this.$apollo.queries.orders.refetch();

      this.$eventHub.$emit("updateCartMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";

.cart {
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
  }
}
</style>
